import { ProductsProvider } from '@src/data/types/ProductsProvider'
import { FinancingConfigDto } from '@src/data/types/FinancingConfigDto'
import { Merchant, Territory } from '../../types'
import { DocumentType } from '../../types/DocumentType'
import { TaskType } from '../../types/TaskType'
import { TranslatedEnum } from '../../types/TranslatedEnum'
import { makeCreate, makeGetListFromApi, makeGetSingleFromApi, makeGetListWithIdsFromApi } from '../make-api'
import { SearchMerchantDto } from './SearchMerchantDto'
import {
  transformTranslatedEnumFromApi,
  transformDocumentTypeFromApi,
  transformTaskTypeFromApi,
} from './config-transform'

export type ValueListItemDto = {
  value: string
  textFr: string
  textEn: string
  priority?: number
  active: boolean
}

export type ValueListDto = {
  id: string
  sortMethod: 'priority' | 'alpha'
  defaultvalue: string | null
  items: ValueListItemDto[]
  expiresOn: number
}

const transMerchantFromApi = (data: Merchant) => ({
  ...data,
  province: data.province?.toUpperCase() ?? '',
})

const configApi = {
  getMerchants: makeCreate<SearchMerchantDto, Merchant[]>('/Merchants/search', undefined, (data: Merchant) =>
    transMerchantFromApi(data),
  ),
  getMerchantById: makeGetSingleFromApi<Merchant>('/Merchants/{id}', transMerchantFromApi),
  getValueList: makeGetSingleFromApi<ValueListDto>('/Configs/{listId}'),
  getTerritories: makeGetListFromApi<Territory>('/Territories'),
  getEnum: makeGetSingleFromApi<Record<string, Record<string, TranslatedEnum>>>(
    '/Enums',
    transformTranslatedEnumFromApi,
  ),
  getDocumentTypes: makeGetSingleFromApi<Record<number, DocumentType>>('/DocumentType', transformDocumentTypeFromApi),
  getFinancingConfigs: makeGetSingleFromApi<FinancingConfigDto[]>('/FinancingConfigs'),
  getTaskTypes: makeGetSingleFromApi<Record<number, TaskType>>('/TaskType', transformTaskTypeFromApi),
  getIFinanceHolidays: makeGetListWithIdsFromApi<Date>('/FinancingCompanyEnum/Companies/iFinance/Holidays/{year}'),
  getProductProviders: makeGetListFromApi<ProductsProvider>('/ProductProvider'),
}

export default configApi
