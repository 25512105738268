import { yupResolver } from '@hookform/resolvers/yup'
import { Delete } from '@mui/icons-material'
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { compareAsc } from 'date-fns'
import { debounce, sumBy } from 'lodash-es'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  buildEditPersonalLoanWorksheetDtoSchema,
  CreditApplicationMessage,
  EditPersonalLoanWorksheetDto,
  EFinancingProgram,
  EMerchantIds,
  EPaymentMethod,
  EPaymentMethodList,
  EPaymentPlan,
  EPlanType,
  EProvince,
  EWorksheetStatus,
  Merchant,
} from '@src/data/types'
import { TRootState, useAppDispatch, useAppSelector } from '@src/data/store'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { appActions, appSelectors } from '@src/data/store/AppStore'
import { availableCreditSelectors } from '@src/data/store/AvailableCredit/available-credit-store'
import {
  personalLoanWorksheetActions,
  personalLoanWorksheetSelectors,
} from '@src/data/store/PersonalLoanWorksheet/personal-loan-worksheet-store'
import { messageEffects, messageSelectors } from '@src/data/store/Message'
import { userSelectors } from '@src/data/store/UserStore'
import { FormatCurrency, formatDate, formatPaymentPlan, normalizeNumber } from '@src/services/Formatter'
import { MerchantPayment } from '@src/data/types/MerchantPayment'
import {
  Breadcrumb,
  buildSelectValueListFromEnum,
  DatePicker,
  IconButtonWithTooltip,
  InputTextField,
  PageError,
  PageSpinner,
  SelectComponent,
} from '@src/components'
import { reportErrorToConsole } from '@src/services/error-logger'
import { personalLoanWorksheetEffects } from '@src/data/store/PersonalLoanWorksheet'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import { canComputeFunding, getEmptyComputedFundingDto } from '@src/containers/ViewWorksheet/viewWorksheet-selectors'
import MerchantPaymentInstructions from '@src/containers/ViewCreditApplicationPage/components/MerchantPaymentInstructions'
import PrivateMessage from '@src/containers/ViewCreditApplicationPage/components/privateMessage'
import { fullCreditApplicationEffects } from '@src/data/store/FullCreditApplication'
import SelectMerchantDialog from '@src/components/SelectMerchantDialog'
import availableCreditEffects from '@src/data/store/AvailableCredit/available-credit-effects'
import allApis from '../../../data/api'
import {
  aprThresholds,
  useAprTooHigh,
  useCantSelectDate,
  useFirstPaymentDateOptions,
  usePaymentPlanList,
  usePossibleTerms,
} from '../editWorksheet-hooks'

const PAY_CUSTOMER_IDS: string[] = [EMerchantIds.PayCustomerByBankTransfer, EMerchantIds.PayCustomerByCheque]

const EditPersonalLoanWorksheetPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const financingConfig = useAppSelector((state: TRootState) =>
    appSelectors.getFinancingConfig(state, creditApplication?.financingProgramId),
  )

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<EditPersonalLoanWorksheetDto>({
    mode: 'onBlur',
    defaultValues: {} as EditPersonalLoanWorksheetDto,
    resolver: yupResolver(buildEditPersonalLoanWorksheetDtoSchema(financingConfig)),
    context: { loanPurposeId: creditApplication?.loanPurposeId },
  })

  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect()
  const theme = useTheme()
  const apiClient = useAppSelector(appSelectors.getApiClient)
  const availableCredit = useAppSelector(availableCreditSelectors.getAvailableCredit)
  const creditInProgress = useAppSelector(availableCreditSelectors.getCreditInProgress)
  const isLoading = useAppSelector(personalLoanWorksheetSelectors.isSavingPersonalLoanWorksheet)
  const isLoadingCreditApplication = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const isComputingFunding = useAppSelector(personalLoanWorksheetSelectors.isComputePersonalLoanFunding)
  const error = useAppSelector(appSelectors.getBusinessError)
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const personalLoanWorksheet = useAppSelector(personalLoanWorksheetSelectors.getCurrent)
  const computedInfo = useAppSelector(personalLoanWorksheetSelectors.getComputedInfo)
  const totalFees = useAppSelector(personalLoanWorksheetSelectors.getTotalfees)
  const totalInsurance = useAppSelector(personalLoanWorksheetSelectors.getTotalInsurance)
  const listHolidays = useAppSelector(appSelectors.getHolidays)
  const paymentPlanList = usePaymentPlanList(creditApplication?.createdOn, merchant?.paymentPlans)
  const cantSelectDate = useCantSelectDate(listHolidays)
  const computeFirstPaymentDateOptions = useFirstPaymentDateOptions(listHolidays)
  const aprTooHigh = useAprTooHigh(computedInfo, creditApplication)
  const privateMessages = useAppSelector(messageSelectors.getPrivateMessages)
  const user = useAppSelector(userSelectors.getUser)
  const firstPaymentOn = watch('firstPaymentOn')
  const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const [firstPaymentDateOptions, setFirstPaymentOptions] = useState<Date[]>([])
  const [isMerchantDialogOpen, setIsMerchantDialogOpen] = useState<boolean>(false)
  const [isVariableInterest, setIsVariableInterest] = useState(false)
  const possibleTerms = usePossibleTerms(
    creditApplication?.finalCreditDecision.maxTermDuration,
    isVariableInterest,
    creditApplication?.financingProgramId,
    amountRequested,
  )
  const [isLoadingMerchants, setIsLoadingMerchants] = useState(true)
  const [paymentMerchantsByIds, setPaymentMerchantsByIds] = useState<Record<string, Merchant>>({})

  const easternTime = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' })
  const currentDateAsEasternTimeZone = new Date(easternTime)

  useEffect(() => {
    if (amountRequested) trigger('term').catch(reportErrorToConsole)
  }, [amountRequested, trigger])

  // dynamic values
  const payments = watch('merchantPayments')
  const paymentFrequency = watch('paymentFrequency')
  const paymentPlanId = watch('paymentPlanId') as EPaymentPlan
  const deliveryOn = watch('deliveryOn')
  const term = watch('term')
  const includeInsurance = watch('includeInsurance')
  const provinceSupportsInsurance =
    creditApplication?.applicant.currentAddress.stateIso &&
    creditApplication.applicant.currentAddress.stateIso !== EProvince.quebec &&
    creditApplication.applicant.currentAddress.stateIso !== EProvince.saskatchewan
  const totalObligation = amountRequested + (computedInfo?.totalInterestAmount ?? 0) + totalFees + totalInsurance
  const planName = creditApplication?.normsCreditDecision?.planName ?? EPlanType.default

  const setPayments = useCallback(
    (merchantPayment: MerchantPayment[]) => {
      setValue('merchantPayments', merchantPayment)
    },
    [setValue],
  )

  const possiblePaymentMethods = useMemo(
    () => buildSelectValueListFromEnum(EPaymentMethodList, 'enum.paymentMethods'),
    [],
  )

  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.Personal}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  const selectedPlan = useMemo(
    () => merchant?.paymentPlans?.find((p) => p.id === paymentPlanId),
    [merchant?.paymentPlans, paymentPlanId],
  )

  useEffect(() => {
    if (creditApplication?.id) {
      dispatchEffect(
        availableCreditEffects.getAvailableCredit(creditApplication.id, creditApplication.financingProgramId),
      ).catch(reportErrorToConsole)
    }
  }, [creditApplication, dispatchEffect])

  // reset form on worksheet changed
  useEffect(() => {
    if (personalLoanWorksheet && merchant) {
      reset(personalLoanWorksheet as EditPersonalLoanWorksheetDto)
      setPayments(personalLoanWorksheet.merchantPayments ?? [])
      setValue('firstPaymentOn', personalLoanWorksheet.firstPaymentOn ?? new Date())

      const removedMerchantIds = [...PAY_CUSTOMER_IDS, merchant.id]
      const merchantIdsToFetch = personalLoanWorksheet.merchantPayments
        .filter((p) => removedMerchantIds.indexOf(p.merchantId) === -1)
        .map((p) => p.merchantId)

      const newPaymentMechantsByIds = {
        [merchant.id]: merchant,
      }
      if (merchantIdsToFetch.length > 0 && apiClient) {
        const promises = merchantIdsToFetch.map((merchantId) =>
          allApis.config.getMerchantById(apiClient, { id: merchantId }),
        )
        Promise.all(promises)
          .then((results) => {
            results.forEach((m) => {
              newPaymentMechantsByIds[m.id] = m
            })
            setPaymentMerchantsByIds(newPaymentMechantsByIds)
            setIsLoadingMerchants(false)
          })
          .catch(reportErrorToConsole)
      } else {
        setPaymentMerchantsByIds(newPaymentMechantsByIds)
        setIsLoadingMerchants(false)
      }
    }
  }, [personalLoanWorksheet, merchant, apiClient, reset, setPayments, setValue])

  const computeFunding = useCallback(
    (computeDto: FundingInfoDto) => {
      return dispatchEffect(personalLoanWorksheetEffects.computePersonalLoanFunding(computeDto))
    },
    [dispatchEffect],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceComputeFunding = useCallback(debounce(computeFunding, 500), [computeFunding])

  // recompute funding
  useEffect(() => {
    const interestRate = creditApplication?.finalCreditDecision.interestRate ?? 0
    const hasCoApplicant = creditApplication?.coApplicant !== null
    const stateIso = creditApplication?.applicant.currentAddress.stateIso
    const requestId = nanoid()
    if (
      amountRequested >= financingConfig.minimumLoanAmount &&
      paymentFrequency &&
      term &&
      deliveryOn &&
      paymentPlanId &&
      firstPaymentOn &&
      stateIso &&
      personalLoanWorksheet &&
      merchant
    ) {
      const computeDto: FundingInfoDto = {
        financingProgramId: creditApplication?.financingProgramId,
        amountRequested,
        includeInsurance,
        insuranceCompanyId: personalLoanWorksheet.insuranceCompanyId,
        paymentFrequency,
        paymentPlanId,
        term,
        interestRate,
        hasCoApplicant,
        stateIso,
        deliveryOn,
        firstPaymentOn,
        requestId,
        creditApplicationId: creditApplication.id,
        merchantId: merchant.id,
      }

      if (canComputeFunding(computeDto, financingConfig.minimumLoanAmount))
        debounceComputeFunding(computeDto)?.catch(reportErrorToConsole)
      else {
        const emptyComputedDto = getEmptyComputedFundingDto()

        dispatch(personalLoanWorksheetActions.setFundingComputed(emptyComputedDto))
      }
    }
  }, [
    amountRequested,
    paymentFrequency,
    paymentPlanId,
    deliveryOn,
    firstPaymentOn,
    term,
    includeInsurance,
    creditApplication,
    dispatch,
    id,
    debounceComputeFunding,
    merchant?.id,
    merchant,
    personalLoanWorksheet,
    financingConfig.minimumLoanAmount,
  ])

  useEffect(() => {
    const isVariable = Boolean(paymentPlanId && paymentPlanId !== EPaymentPlan.regularDailyInterests)

    if (isVariable && selectedPlan) {
      setValue('term', selectedPlan.loanTerm)
      if (payments) setPayments(payments.filter((p) => !PAY_CUSTOMER_IDS.includes(p.merchantId)))
    }

    setIsVariableInterest(isVariable)
  }, [paymentPlanId, payments, selectedPlan, selectedPlan?.loanTerm, setPayments, setValue])

  const getMerchantFees = () => {
    if (selectedPlan) return FormatCurrency((selectedPlan.merchantFeeRate / 100) * amountRequested)
    return ''
  }
  // recompute first payment options and date
  useEffect(() => {
    const options = computeFirstPaymentDateOptions(deliveryOn)
    setFirstPaymentOptions(options)
    const currentValues = getValues()
    const currentFirstPaymentDateAsString = formatDate(currentValues.firstPaymentOn)
    const availableDatesAsString: string[] = []
    options.forEach((item) => {
      availableDatesAsString.push(formatDate(item))
    })

    if (availableDatesAsString.length > 0 && !availableDatesAsString.includes(currentFirstPaymentDateAsString)) {
      setValue('firstPaymentOn', options[0])
    }
  }, [deliveryOn, computeFirstPaymentDateOptions, getValues, setValue])

  // callbacks
  const getMerchantName = useCallback(
    (mId: string) => {
      if (creditApplication && PAY_CUSTOMER_IDS.some((n) => n === mId)) {
        return `${creditApplication.applicant.firstName} ${creditApplication.applicant.lastName}`
      }
      return paymentMerchantsByIds[mId]?.name ?? ''
    },
    [paymentMerchantsByIds, creditApplication],
  )

  const handleMerchantsSelected = useCallback(
    (newMerchants: Merchant[]) => {
      setPaymentMerchantsByIds((oldPaymentMerchantsByIds) => {
        const newPaymentMerchants = { ...oldPaymentMerchantsByIds }
        newMerchants.forEach((m) => {
          newPaymentMerchants[m.id] = m
        })
        return newPaymentMerchants
      })

      const currentPayments: MerchantPayment[] = [...payments]

      newMerchants.forEach((merch: Merchant) => {
        const existing = currentPayments.find((p) => p.merchantId === merch.id)

        if (!existing) {
          currentPayments.push({
            merchantId: merch.id,
            amount: 0,
            paymentMethod: merch.defaultPaymentMethod ?? EPaymentMethod.check,
          })
        }
      })

      setPayments(currentPayments)

      setIsMerchantDialogOpen(false)
    },
    [payments, setPayments],
  )

  const addBorrower = () => {
    const currentPayments: MerchantPayment[] = [...payments]
    const existing = currentPayments.find((p) => PAY_CUSTOMER_IDS.some((n) => n === p.merchantId))
    if (!existing) {
      currentPayments.push({
        merchantId: EMerchantIds.PayCustomerByBankTransfer,
        amount: 0,
        paymentMethod: EPaymentMethod.bankTransfer,
      })
    }
    setPayments(currentPayments)
  }

  const onDeleteMerchantPayment = (payment: MerchantPayment) => {
    const newPayments = payments.filter((p) => p.merchantId !== payment.merchantId)

    setPayments(newPayments)
  }

  const onMerchantPaymentAmountEdited = (newAmount: string, payment: MerchantPayment) => {
    const paymentIndex = payments.indexOf(payment)
    const tempPayments: MerchantPayment[] = [...payments]
    const normalizedAmount = normalizeNumber(newAmount)

    tempPayments[paymentIndex] = {
      merchantId: payment.merchantId,
      amount: Number(normalizedAmount),
      paymentMethod: payment.paymentMethod,
    }

    setPayments(tempPayments)
  }

  const onMerchantPaymentMethodEdited = (event: React.ChangeEvent<HTMLInputElement>, payment: MerchantPayment) => {
    const paymentIndex = payments.indexOf(payment)
    const tempPayments: MerchantPayment[] = [...payments]
    let paymentMerchantId = payment.merchantId
    if (PAY_CUSTOMER_IDS.some((n) => n === payment.merchantId)) {
      if ((event.target.value as EPaymentMethod) === EPaymentMethod.bankTransfer) {
        paymentMerchantId = EMerchantIds.PayCustomerByBankTransfer
      } else {
        paymentMerchantId = EMerchantIds.PayCustomerByCheque
      }
    }
    tempPayments[paymentIndex] = {
      merchantId: paymentMerchantId,
      amount: payment.amount,
      paymentMethod: event.target.value as EPaymentMethod,
    }

    setPayments(tempPayments)
  }

  const closeMerchantDialog = useCallback(() => {
    setIsMerchantDialogOpen(false)
  }, [])

  const onFormSubmitted = async (data: EditPersonalLoanWorksheetDto) => {
    const expiry = new Date(creditApplication!.expiresOn)
    const receivedOnPlus90Days = new Date(creditApplication!.applicant.hardHitReport.receivedOn)
    receivedOnPlus90Days.setDate(receivedOnPlus90Days.getDate() + 90)
    const totalPayments = normalizeNumber(sumBy(payments, 'amount')?.toString()) ?? 0
    const mainPayment = payments.find((p) => p.merchantId === merchant?.id)
    const deliveryOnDate = new Date(`${getValues('deliveryOn')}T20:00:00`) // 20PM Local Time
    const deliveryOnUTC = new Date(`${getValues('deliveryOn')}T00:00:00`)
    const deliveryOnDateAsEasternTimeZone = new Date(
      deliveryOnDate.toLocaleString('en-US', { timeZone: 'America/Toronto' }),
    )
    const baseActivationDateAsEasternTimeZone = new Date(
      currentDateAsEasternTimeZone.getFullYear(),
      currentDateAsEasternTimeZone.getMonth(),
      currentDateAsEasternTimeZone.getDate(),
      0,
      0,
      0,
    )

    if (
      isVariableInterest &&
      (!mainPayment ||
        (selectedPlan && mainPayment && (selectedPlan.merchantFeeRate / 100) * amountRequested > mainPayment.amount))
    )
      dispatch(
        appActions.setBusinessErrors([
          {
            message: t('worksheetCommon.merchantMustHaveFees', '', { merchantFee: getMerchantFees() }),
          },
        ]),
      )
    else if (computedInfo.effectiveRate > financingConfig.effectiveRateThreshold) {
      dispatch(
        appActions.setBusinessErrors([
          {
            message: t('worksheetCommon.effectiveRateTooHigh', '', {
              effectiveRate: computedInfo.effectiveRate,
              maxRate: financingConfig.effectiveRateThreshold,
            }),
          },
        ]),
      )
    } else if (compareAsc(deliveryOnDateAsEasternTimeZone, baseActivationDateAsEasternTimeZone) === -1) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateInPast' }]))
    } else if (cantSelectDate(deliveryOnDateAsEasternTimeZone)) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateIsHoliday' }]))
    } else if (amountRequested > availableCredit) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.amountRequestedHigherThanAvailableCredit' }]))
    } else if (compareAsc(deliveryOnUTC, receivedOnPlus90Days) === 1)
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateReport90Days' }]))
    else if (compareAsc(deliveryOnUTC, expiry) === 1)
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.deliveryOnEarlierExpiration' }]))
    else if (totalPayments !== amountRequested) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.merchantPaymentTotalMismatch' }]))
    } else {
      data.amountRequested = amountRequested
      data.status = EWorksheetStatus.Active
      data.merchantPayments = payments
      await dispatchEffect(personalLoanWorksheetEffects.update(data)).then(() =>
        dispatchEffect(fullCreditApplicationEffects.getById(creditApplication!.id, EFinancingProgram.Personal)).then(
          () =>
            navigate(`/Applications/${EFinancingProgram.Personal}/${creditApplication!.id}/view`, { replace: true }),
        ),
      )
    }
  }

  const getFilteredPaymentMethods = (payment: MerchantPayment) => {
    if (PAY_CUSTOMER_IDS.some((n) => n === payment.merchantId)) {
      return possiblePaymentMethods.filter((m) => m.value !== EPaymentMethod.WIRE && m.value !== EPaymentMethod.DEFT)
    }
    return possiblePaymentMethods.filter((m) => m.value !== EPaymentMethod.bankTransfer)
  }

  const isPaymentMethodDisabled = (payment: MerchantPayment) => {
    // disabled for the time being. ALIS does not support custom merchant methods.
    // remove this disabling function once ALIS has support.
    if (PAY_CUSTOMER_IDS.includes(payment.merchantId)) return false
    return payment.paymentMethod === paymentMerchantsByIds[payment.merchantId].defaultPaymentMethod
  }

  const handleDeletingPrivateMessage = (message: CreditApplicationMessage) => {
    if (creditApplication?.id) {
      return dispatchEffect(messageEffects.deleteMessage(message, creditApplication.financingProgramId))
    }
    return null
  }

  return (
    <div>
      <PageSpinner withBackdrop isLoading={isLoading && isLoadingCreditApplication} />
      {!isLoading && (
        <Stack>
          <PageError errors={error} />
          {creditApplication && aprTooHigh && (
            <Alert severity="error">
              {t('common.errors.aprTooHigh', '', {
                threshold: creditApplication.applicant.currentAddress.stateIso
                  ? aprThresholds[creditApplication.applicant.currentAddress.stateIso]
                  : '',
                stateIso: creditApplication.applicant.currentAddress.stateIso
                  ? t(`enum.eProvince.${creditApplication.applicant.currentAddress.stateIso}`)
                  : '',
              })}
            </Alert>
          )}
        </Stack>
      )}
      <Breadcrumb trees={breadCrumbs} />
      <form onSubmit={handleSubmit(onFormSubmitted, reportErrorToConsole)}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <InputTextField value={t(`credit.plans.${planName}`)} label={t('worksheetCommon.program')} disabled />
          <InputTextField disabled value={creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0} />
          <InputTextField label={t('worksheetCommon.creditInProgress')} disabled value={creditInProgress} />
          <InputTextField label={t('worksheetCommon.availableCredit')} disabled value={availableCredit} />
          <div style={{ marginTop: '1%' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={aprTooHigh || isComputingFunding || !computedInfo || isLoading}
            >
              {t('common.save')}
            </Button>
          </div>
        </Stack>
        {creditApplication && (
          <PrivateMessage
            messages={privateMessages}
            handleDeletingPrivateMessage={handleDeletingPrivateMessage}
            currentUser={user}
            creditAppCreationDate={creditApplication.createdOn}
          />
        )}
        {merchant && <MerchantPaymentInstructions merchant={merchant} />}
        <Grid container marginTop={5}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <InputTextField
                  InputProps={{
                    endAdornment: '$',
                  }}
                  {...register('amountRequested')}
                  error={errors?.amountRequested}
                  label={t('worksheetCommon.amountRequested')}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <DatePicker
                  name="deliveryOn"
                  control={control}
                  label={t('worksheetCommon.activationDate')}
                  error={errors?.deliveryOn}
                  disablePast
                  minDate={currentDateAsEasternTimeZone}
                  shouldDisableDate={cantSelectDate}
                  disabled={creditApplication?.editLocked}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={firstPaymentDateOptions.map((item) => ({
                    label: formatDate(item),
                    value: formatDate(item),
                  }))}
                  label={t('worksheetCommon.firstPaymentDate') as string}
                  {...register('firstPaymentOn')}
                  error={errors?.firstPaymentOn}
                  value={formatDate(firstPaymentOn)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={paymentPlanList}
                  label={t('worksheet.paymentPlan') as string}
                  {...register('paymentPlanId')}
                  error={errors?.paymentPlanId}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={[{ label: 'worksheet.monthly', value: 'monthly' }]}
                  label={t('worksheet.paymentFrequency') as string}
                  {...register('paymentFrequency')}
                  error={errors?.paymentFrequency}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  disabled={isVariableInterest}
                  items={possibleTerms.map((item) => ({
                    label: `${item} ${t('worksheet.month')}`,
                    value: item,
                  }))}
                  label={t('worksheet.term') as string}
                  {...register('term')}
                  error={errors?.term}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  {...register('includeInsurance')}
                  control={<Checkbox defaultChecked={personalLoanWorksheet?.includeInsurance} />}
                  label={t('worksheetCommon.addInsurance')}
                  disabled={!provinceSupportsInsurance}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  width="100%"
                  sx={{
                    border: 1,
                    padding: 2,
                    marginTop: 2,
                    borderColor: theme.palette.divider,
                    borderRadius: 1,
                  }}
                >
                  <Grid container marginBottom={4} justifyContent="flex-end" display="flex" gap="0.5rem">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => addBorrower()}
                      disabled={
                        isVariableInterest ||
                        payments?.some((p) => PAY_CUSTOMER_IDS.some((n) => n === p.merchantId)) ||
                        !merchant?.canPayBorrowers
                      }
                    >
                      {t('worksheetCommon.addBorrower')}
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => setIsMerchantDialogOpen(true)}>
                      {t('worksheetCommon.addMerchantPayment')}
                    </Button>
                  </Grid>

                  {payments &&
                    !isLoadingMerchants &&
                    payments.map((p, index) => (
                      <Stack key={p.merchantId} direction="row" justifyContent="space-between" spacing={2}>
                        <Typography sx={{ width: '100%', marginTop: '1rem' }}>
                          {getMerchantName(p.merchantId)}
                        </Typography>
                        <SelectComponent
                          disabled={isPaymentMethodDisabled(p)}
                          items={getFilteredPaymentMethods(p)}
                          label={t('worksheetCommon.paymentMethod') as string}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onMerchantPaymentMethodEdited(e, p)}
                          value={p.paymentMethod}
                        />
                        <InputTextField
                          {...register(`merchantPayments.${index}.amount`)}
                          sx={{ width: '30rem' }}
                          label={t('worksheetCommon.paymentAmount')}
                          InputProps={{
                            endAdornment: '$',
                          }}
                          onBlur={(content) => onMerchantPaymentAmountEdited(content.target.value, p)}
                        />
                        <div style={{ marginTop: '1%' }}>
                          <IconButtonWithTooltip
                            disabled={isVariableInterest && p.merchantId === merchant?.id}
                            onClick={() => onDeleteMerchantPayment(p)}
                            tooltip="delete"
                            color="primary"
                            icon={<Delete />}
                          />
                        </div>
                      </Stack>
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                border: 1,
                padding: 2,
                marginLeft: 5,
                paddingBottom: 10,
              }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('worksheetCommon.fundingSummary')} :
                </Typography>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.loanAmount')} :</Typography>
                  <Typography>{`${FormatCurrency(getValues('amountRequested'))}`}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {`${t('worksheetCommon.interest')} (${
                      isVariableInterest
                        ? formatPaymentPlan(selectedPlan)
                        : `${creditApplication?.finalCreditDecision?.interestRate}%`
                    })`}
                    :{' '}
                  </Typography>
                  <Typography>{FormatCurrency(computedInfo?.totalInterestAmount)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{`${t('worksheetCommon.financeFee')} (${computedInfo?.lenderFeeRate}%)`}:</Typography>
                  <Typography>{FormatCurrency(totalFees)}</Typography>
                </Stack>

                {isVariableInterest && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>
                      {`${t('worksheetCommon.merchantFee')} (${selectedPlan?.merchantFeeRate}%)`} :
                    </Typography>
                    <Typography>{getMerchantFees()}</Typography>
                  </Stack>
                )}

                {provinceSupportsInsurance && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>{t('worksheetCommon.insurance')} :</Typography>
                    <Typography>{FormatCurrency(totalInsurance)}</Typography>
                  </Stack>
                )}

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.totalObligation')} :</Typography>
                  <Typography>{FormatCurrency(totalObligation)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.annualPercentageRate')} :</Typography>
                  <Typography>{computedInfo.effectiveRate} %</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" paddingTop={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('worksheetCommon.payment')}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.maxPmtAmount')} :</Typography>
                  <Typography>{FormatCurrency(creditApplication?.finalCreditDecision.maxPmtAmount)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.paymentFrequency')} :</Typography>
                  <Typography>
                    {personalLoanWorksheet?.paymentFrequency
                      ? t(`worksheet.${personalLoanWorksheet?.paymentFrequency}`)
                      : t('worksheet.monthly')}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.frequencyAmount')} :</Typography>
                  <Typography>{FormatCurrency(computedInfo?.paymentForFrequency)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.firstPaymentDate')} :</Typography>
                  <Typography>{formatDate(firstPaymentOn)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" paddingTop={3}>
                  <Typography fontWeight="bold">{t('worksheetCommon.amountToBePaid')} :</Typography>
                  <Typography>{FormatCurrency(getValues('amountRequested') ?? 0)}</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>

      <SelectMerchantDialog
        open={isMerchantDialogOpen}
        title={t('worksheetCommon.addMerchantPayment')}
        onConfirm={handleMerchantsSelected}
        onCancel={closeMerchantDialog}
        allowMultiple
        financingProgramId={EFinancingProgram.Personal}
        label={t('taskManager.merchantName')}
        financingCompanyId={selectedCompany}
      />
    </div>
  )
}

export default EditPersonalLoanWorksheetPage
