import { Grid, Stack } from '@mui/material'
import { Breadcrumb, InputTextField, PageError, PageSpinner } from '@src/components'
import PendingMessage from '@src/components/PendingMessage'
import PrivateMessage from '@src/containers/ViewCreditApplicationPage/components/privateMessage'
import WarningMessageAccordion from '@src/containers/ViewCreditApplicationPage/components/warningMessageAccordion'
import { useAppSelector, useAppDispatch } from '@src/data/store'
import { appSelectors } from '@src/data/store/AppStore'
import { availableCreditSelectors } from '@src/data/store/AvailableCredit/available-credit-store'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { messageEffects, messageSelectors } from '@src/data/store/Message'
import { productsWorksheetActions, productsWorkSheetSelectors } from '@src/data/store/ProductsWorksheet'
import { userSelectors } from '@src/data/store/UserStore'
import {
  BaseWorksheetFundingThresholdExtend,
  CreditApplicationMessage,
  EFinancingProgram,
  EProvince,
  EWorksheetDecision,
  NormsMessage,
  WorksheetDecision,
} from '@src/data/types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductsInsurancePlan, ProductsWorksheetDecisionDTO } from '@src/data/types/ProductsWorksheetSchema'
import { fullCreditApplicationEffects } from '@src/data/store/FullCreditApplication'
import { documentSelectors } from '@src/data/store/Document'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ProductsComputeRequestDto } from '@src/data/types/ProductsComputeRequestDto'
import productsWorksheetEffects from '@src/data/store/ProductsWorksheet/products-worksheet-effects'
import { ProductsProvider } from '@src/data/types/ProductsProvider'
import WorksheetDecisionSection from '../components/WorksheetDecisionSection'
import ProductsSummaryBox from '../components/ProductsSummaryBox'
import WorksheetDecisionDialog from '../components/WorksheetDecisionDialog'
import { useDialogDecisionDefaultValue } from '../viewWorksheet-hooks'
import TransactionSection from '../components/TransactionSection'
import { getEmptyProductsComputeResponse, getProvidersInsurancePlans } from '../viewWorksheet-selectors'

const ProductsWorksheetViewPage = () => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const dispatch = useAppDispatch()
  const EditDecisionDialogOpen = 'EditDecisionDialogOpen'
  const [currentOpenDialog, setCurrentOpenDialog] = React.useState<string>('')
  const [productsInsurances, setProductsInsurances] = React.useState<ProductsInsurancePlan[]>([])

  const productsProviders: ProductsProvider[] = useAppSelector(appSelectors.getProductProviders)
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const productsWorksheet = useAppSelector(productsWorkSheetSelectors.getCurrent)
  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)
  const error = useAppSelector(appSelectors.getBusinessError)
  const availableCredit = useAppSelector(availableCreditSelectors.getAvailableCredit)
  const creditInProgress = useAppSelector(availableCreditSelectors.getCreditInProgress)
  const commonNormsMessage: NormsMessage[] = useAppSelector(creditSelectors.getCurrentCreditApplicationSpecificNorms)
  const user = useAppSelector(userSelectors.getUser)
  const privateMessages = useAppSelector(messageSelectors.getPrivateMessages)
  const computedInfo = useAppSelector(productsWorkSheetSelectors.getComputedInfo)
  const worksheetNormWarningMessages = useAppSelector(productsWorkSheetSelectors.getCurrentWorksheetNormWarningMessages)
  const areAllTasksRequiredForCVTCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForCVTCompleted)
  const areAllDocumentsRequiredForCVTApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredForCVT)
  const confirmAllIncomesStatus = useAppSelector(creditSelectors.getConfirmAllIncomesStatus)
  const totalObligation = (productsWorksheet?.amountRequested ?? 0) + (productsWorksheet?.totalInterestAmount ?? 0)
  const activePaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)

  const editDisabled = creditApplication?.editLocked === true || finalDecisionIsPending
  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.Products}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  const dialogDecisionDefaultValue = useDialogDecisionDefaultValue(
    creditApplication!,
    productsWorksheet!,
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus!,
  )

  const handleDeletingPrivateMessage = async (message: CreditApplicationMessage) => {
    if (creditApplication?.id) {
      await dispatchEffect(messageEffects.deleteMessage(message, creditApplication.financingProgramId))
    }
  }

  React.useEffect(() => {
    if (productsWorksheet) setProductsInsurances(getProvidersInsurancePlans(productsWorksheet, productsProviders))
  }, [productsProviders, productsWorksheet])

  React.useEffect(() => {
    if (creditApplication && productsWorksheet && Object.keys(computedInfo).length === 0) {
      const stateIso = creditApplication?.applicant.currentAddress.stateIso

      const computeDto: ProductsComputeRequestDto = {
        amountRequested: productsWorksheet.amountRequested ?? 0,
        frequency: productsWorksheet.paymentFrequency,
        term: productsWorksheet.term,
        stateIso: stateIso as EProvince,
      }

      if (computeDto) {
        dispatchEffect(productsWorksheetEffects.computeProductsLoan(computeDto)).catch(reportErrorToConsole)
      } else {
        const emptyComputedDto = getEmptyProductsComputeResponse()

        dispatch(productsWorksheetActions.setLoanComputed(emptyComputedDto))
      }
    }
  }, [productsWorksheet, computedInfo, creditApplication, dispatchEffect, dispatch])

  // callbacks
  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [])

  const handleSaveDecision = useCallback(
    async (data: WorksheetDecision) => {
      if (productsWorksheet) {
        const updatedFinalDecision: ProductsWorksheetDecisionDTO = {
          creditApplicationId: productsWorksheet.creditApplicationId,
          decision: data.decision,
          otherReason: data.otherReason,
          versionTag: productsWorksheet.versionTag,
        } as ProductsWorksheetDecisionDTO

        if (data.decision === EWorksheetDecision.ApprovedWithCondition) {
          const fundingThresholdExtend: BaseWorksheetFundingThresholdExtend = {
            maxPmtAmount: data.maxPmtAmount,
            maxTotalAmountFinanced: data.maxTotalAmountFinanced,
            maxTermDuration: data.maxTermDuration,
          }
          updatedFinalDecision.fundingThresholdExtend = fundingThresholdExtend
        }

        await dispatchEffect(
          productsWorksheetEffects.updateDecision({
            ...updatedFinalDecision,
            creditApplicationId: creditApplication?.id,
          }),
        ).then(async () => {
          await dispatchEffect(fullCreditApplicationEffects.getById(creditApplication!.id, EFinancingProgram.Products))
          closeDialogs()
        })
      }
    },
    [productsWorksheet, creditApplication, closeDialogs, dispatchEffect],
  )

  return (
    <div>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && productsWorksheet && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <Stack>
            <PageError errors={error} />
            {finalDecisionIsPending && <PendingMessage />}
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <InputTextField value="IF" label={t('worksheetCommon.program')} disabled />
              <InputTextField
                label={t('worksheetCommon.creditLimit')}
                disabled
                value={creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0}
              />
              <InputTextField label={t('worksheetCommon.creditInProgress')} disabled value={creditInProgress} />
              <InputTextField label={t('worksheetCommon.availableCredit')} disabled value={availableCredit} />
            </Stack>
            {commonNormsMessage?.length > 0 && (
              <WarningMessageAccordion
                applicantCreditWarnings={null}
                coapplicantCreditWarnings={null}
                commonCreditWarnings={commonNormsMessage}
                worksheetWarnings={null}
                expanded={false}
              />
            )}
            <PrivateMessage
              messages={privateMessages}
              handleDeletingPrivateMessage={handleDeletingPrivateMessage}
              currentUser={user}
              creditAppCreationDate={creditApplication.createdOn}
            />
            <Grid container textAlign="center" justifyContent="space-around" p={4}>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <ProductsSummaryBox worksheet={productsWorksheet} computedInfo={computedInfo} />
              </Grid>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <WorksheetDecisionSection
                  worksheet={productsWorksheet}
                  onClick={() => setCurrentOpenDialog(EditDecisionDialogOpen)}
                  editDisabled={editDisabled}
                  userCanEditWorksheet={user?.rights.canEditWorksheet === true}
                />
              </Grid>
            </Grid>
            <TransactionSection
              worksheet={productsWorksheet}
              creditApplication={creditApplication}
              computedInfo={computedInfo}
              paymentMerchantsByIds={null}
              totalObligation={totalObligation}
              merchantPaymentPlan={activePaymentPlan ?? null}
              productsPlans={productsInsurances}
            />
          </Stack>
          <WorksheetDecisionDialog
            open={currentOpenDialog === EditDecisionDialogOpen}
            title={t('worksheet.decision')}
            onCancel={closeDialogs}
            onConfirm={handleSaveDecision}
            worksheetWarnings={worksheetNormWarningMessages}
            defaultValue={dialogDecisionDefaultValue}
            worksheet={productsWorksheet}
            creditapplication={creditApplication}
          />
        </>
      )}
    </div>
  )
}

export default ProductsWorksheetViewPage
