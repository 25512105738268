import {
  Ban,
  CreditApplication,
  CreditApplicationTask,
  CreditTaskComment,
  EFinancingProgram,
  PreviousApplication,
  ProofsOfRelease,
} from '../../types'
import allApi from '../../api'
import {
  makeGetById,
  makeGetList,
  makeSave,
  makeGetListWithIds,
  makeDelete,
  makeDownloadWithObject,
  makeDeleteWithReturn,
} from '../effects/standard-side-effects'
import {
  GET_CREDIT_BY_ID,
  creditSlice,
  SAVE_CREDIT,
  GET_CREDITS_BY_FILTERS,
  GET_CURRENT_CREDIT_TASKS,
  SAVE_CREDIT_TASK,
  SAVE_CREDIT_TASK_COMMENT,
  SAVE_FINAL_CREDIT_DECISION,
  REMOVE_CREDIT_TASK,
  SAVE_BANISHMENT,
  CHANGE_STATUS,
  GET_PREVIOUS_APPLICANT_APPLICATIONS_BY_FILTER,
  GET_PREVIOUS_COAPPLICANT_APPLICATIONS_BY_FILTER,
  DOWNLOAD_CREDIT_PDF,
  GET_INCOMES_HISTORY,
  GET_CREDIT_APPLICATION_LOCK,
  CREATE_CREDIT_APPLICATION_LOCK,
  CONFIRM_ALL_INCOMES,
  DELETE_CREDIT_APPLICATION_LOCK,
  UPDATE_CREDIT_APPLICATION_LOCK,
  CREATE_CVT_NUMBER,
  DOWNLOAD_CVT_PDF,
  SET_REFINANCING,
  PAY_OUT,
  UPDATE_INCOMES,
  SET_PROOF_OF_RELEASE,
  SET_EXISTING_APPLICANT_CUSTOMER_REFERENCE,
  SET_EXISTING_COAPPLICANT_CUSTOMER_REFERENCE,
  CREATE_LOAN,
  SET_PAYMENT_ACCOUNT,
  SEND_FUND_DEPOSIT_CONFIRMATION,
  REFINANCE_CREDIT_APPLICATION,
  COPY_CREDIT_APPLICATION_AS_DRAFT,
  CHANGE_RUSH,
} from './credit-application-store'
import { FinalCreditDecision } from '../../types/CreditDecisionSchema'
import { PreviousApplicationInputDto } from '../../types/PreviousApplicationInputDto'
import { CreditDecisionHistory, IncomesHistory } from '../../types/IncomesHistory'
import { CreditApplicationReservation, CreditApplicationReservationDto } from '../../types/CreditApplicationReservation'
import { Refinancing } from '../../types/RefinancingSchema'
import { EditIncomesDto } from '../../api/credit-application/EditIncomesDto'
import { ExistingCustomerReference, SetCustomerReferenceDto } from '../../types/CustomerReferenceSchema'
import { LoanCustomer, SearchCustomerDto } from '../../types/LoanCustomerSchema'
import { EditBankAccountDto } from '../../api/credit-application/EditBankAccountDto'
import { RefinanceCreditApplicationDto } from '../../api/credit-application/RefinanceCreditApplicationDto'
import { UpdateCreditApplicationMerchantDto } from '../../types/UpdateCreditApplicationMerchantDto'
import { CopyCreditApplicationAsDraftDto } from '../../api/credit-application/CopyCreditApplicationAsDraftDto'

const creditEffects = {
  getById: (id: string, financingProgramId: EFinancingProgram) =>
    makeGetById<CreditApplication>(GET_CREDIT_BY_ID, allApi.credit.getById, creditSlice.actions.setCurrent, {
      id,
      financingProgramId,
    }),
  create: (data: Partial<CreditApplication>) =>
    makeSave<CreditApplication>(SAVE_CREDIT, allApi.credit.create, creditSlice.actions.setCurrent, data),
  update: (data: Partial<CreditApplication>) =>
    makeSave<CreditApplication>(SAVE_CREDIT, allApi.credit.update, creditSlice.actions.setCurrent, data),
  getByFilters: (filters?: Record<string, string | number>) =>
    makeGetList<CreditApplication>(
      GET_CREDITS_BY_FILTERS,
      allApi.credit.getByFilters,
      creditSlice.actions.setList,
      filters,
    ),
  getCurrentCreditApplicationTasks: (creditApplicationId: string) =>
    makeGetListWithIds<CreditApplicationTask>(
      GET_CURRENT_CREDIT_TASKS,
      allApi.creditTask.getTaskByCreditApplicationId,
      creditSlice.actions.setCurrentCreditApplicationTasks,
      { creditApplicationId },
    ),
  createTask: (data: Partial<CreditApplicationTask>) =>
    makeSave<CreditApplicationTask>(
      SAVE_CREDIT_TASK,
      allApi.creditTask.create,
      creditSlice.actions.setCreditTask,
      data,
    ),
  updateTask: (data: Partial<CreditApplicationTask>) =>
    makeSave<CreditApplicationTask>(
      SAVE_CREDIT_TASK,
      allApi.creditTask.update,
      creditSlice.actions.setCreditTask,
      data,
    ),
  updateMerchant: (data: Partial<UpdateCreditApplicationMerchantDto>) =>
    makeSave<UpdateCreditApplicationMerchantDto, CreditApplication>(
      SAVE_CREDIT,
      allApi.credit.updateCreditApplicationMerchant,
      creditSlice.actions.setCurrent,
      data,
    ),
  confirmAllIncomes: (data: Partial<CreditApplication>) =>
    makeSave<CreditApplication, CreditApplicationTask>(
      CONFIRM_ALL_INCOMES,
      allApi.credit.confirmAllIncomes,
      creditSlice.actions.setCreditTask,
      data,
    ),
  createTaskComment: (data: Partial<CreditTaskComment>) =>
    makeSave<CreditTaskComment>(
      SAVE_CREDIT_TASK_COMMENT,
      allApi.creditTaskComment.create,
      creditSlice.actions.setCreditTaskComment,
      data,
    ),
  saveCreditDecision: (data: Partial<FinalCreditDecision>) =>
    makeSave<FinalCreditDecision, CreditApplication>(
      SAVE_FINAL_CREDIT_DECISION,
      allApi.creditDecision.update,
      creditSlice.actions.setCurrent,
      data,
    ),
  deleteTask: (data: CreditApplicationTask, financingProgramId: EFinancingProgram) =>
    makeDelete<CreditApplicationTask>(
      REMOVE_CREDIT_TASK,
      allApi.creditTask.delete,
      creditSlice.actions.removeCreditTask,
      { ...data, financingProgramId },
    ),
  createBanishement: (data: Ban) => makeSave<Ban>(SAVE_BANISHMENT, allApi.banishment.create, null, data),
  refreshCreditReport: (data: {
    creditApplicationId: string
    financingProgramId: EFinancingProgram
    isApplicant: boolean
    versionTag: string
  }) =>
    makeSave<{ creditApplicationId: string; isApplicant: boolean; versionTag: string }, CreditApplication>(
      GET_CREDIT_BY_ID,
      allApi.credit.refreshCreditReport,
      creditSlice.actions.setCurrent,
      data,
    ),
  changeStatus: (data: {
    financingProgramId: EFinancingProgram
    creditApplicationId: string
    status: string
    versionTag: string
  }) =>
    makeSave<{ status: string; versionTag: string }, CreditApplication>(
      CHANGE_STATUS,
      allApi.credit.changeStatus,
      creditSlice.actions.setCurrent,
      data,
    ),
  changeRush: (data: {
    financingProgramId: EFinancingProgram
    creditApplicationId: string
    isRush: boolean
    versionTag: string
  }) =>
    makeSave<{ rush: boolean; versionTag: string }, CreditApplication>(
      CHANGE_RUSH,
      allApi.credit.changeRush,
      creditSlice.actions.setCurrent,
      data,
    ),

  updateIncomes: (data: EditIncomesDto) =>
    makeSave<EditIncomesDto, CreditApplication>(
      UPDATE_INCOMES,
      allApi.credit.updateIncomes,
      creditSlice.actions.setCurrent,
      data,
    ),

  getPreviousApplicantApplication: (filter: PreviousApplicationInputDto) =>
    makeSave<PreviousApplicationInputDto, PreviousApplication[]>(
      GET_PREVIOUS_APPLICANT_APPLICATIONS_BY_FILTER,
      allApi.previousapplication.getByFilters,
      creditSlice.actions.setApplicantPreviousApplications,
      filter,
    ),
  getPreviousCoapplicantApplication: (filter: PreviousApplicationInputDto) =>
    makeSave<PreviousApplicationInputDto, PreviousApplication[]>(
      GET_PREVIOUS_COAPPLICANT_APPLICATIONS_BY_FILTER,
      allApi.previousapplication.getByFilters,
      creditSlice.actions.setCoapplicantPreviousApplications,
      filter,
    ),
  downloadCreditPdf: (creditApplication: CreditApplication) =>
    makeDownloadWithObject<CreditApplication>(DOWNLOAD_CREDIT_PDF, allApi.credit.downloadCreditPDF, creditApplication),

  getIncomesHistory: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetById<IncomesHistory>(
      GET_INCOMES_HISTORY,
      allApi.history.getIncomesHistory,
      creditSlice.actions.setIncomesHistory,
      { creditApplicationId, financingProgramId },
    ),
  getCreditDecisionHistory: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetListWithIds<CreditDecisionHistory>(
      GET_INCOMES_HISTORY,
      allApi.history.getCreditDecisionHistory,
      creditSlice.actions.setDecisionHistory,
      { creditApplicationId, financingProgramId },
    ),
  getCreditApplicationLock: (data: { financingProgramId: EFinancingProgram; creditApplicationId: string }) =>
    makeGetById(
      GET_CREDIT_APPLICATION_LOCK,
      allApi.credit.getCreditApplicationLock,
      creditSlice.actions.setCreditApplicationLock,
      data,
    ),

  lockCreditApplication: (data: { creditApplicationId: string }) =>
    makeSave<{ creditApplicationId: string }, CreditApplication>(
      UPDATE_CREDIT_APPLICATION_LOCK,
      allApi.credit.lockCreditApplication,
      creditSlice.actions.setCurrent,
      data,
    ),

  unlockCreditApplication: (data: { creditApplicationId: string }) =>
    makeSave<{ creditApplicationId: string }, CreditApplication>(
      UPDATE_CREDIT_APPLICATION_LOCK,
      allApi.credit.unlockCreditApplication,
      creditSlice.actions.setCurrent,
      data,
    ),

  payOut: (data: { creditApplicationId: string }) =>
    makeSave<{ creditApplicationId: string }>(PAY_OUT, allApi.credit.payOutCreditApplication, null, data),

  createCreditApplicationLock: (data: CreditApplicationReservationDto) =>
    makeSave<CreditApplicationReservationDto, CreditApplicationReservation>(
      CREATE_CREDIT_APPLICATION_LOCK,
      allApi.credit.createCreditApplicationLock,
      creditSlice.actions.setCreditApplicationLock,
      data,
    ),
  deleteCreditApplicationLock: (data: CreditApplicationReservation) =>
    makeDeleteWithReturn<CreditApplicationReservation>(
      DELETE_CREDIT_APPLICATION_LOCK,
      allApi.credit.deleteCreditApplicationLock,
      creditSlice.actions.setCreditApplicationLock,
      data,
    ),
  updateCreditApplicationLock: (data: CreditApplicationReservation) =>
    makeSave<CreditApplicationReservation>(
      UPDATE_CREDIT_APPLICATION_LOCK,
      allApi.credit.updateCreditApplicationLock,
      creditSlice.actions.setCreditApplicationLock,
      data,
    ),
  createCvtNumber: (data: { id: string; financingProgramId: EFinancingProgram }) =>
    makeSave<CreditApplication>(
      CREATE_CVT_NUMBER,
      allApi.contract.create(data.id, data.financingProgramId),
      creditSlice.actions.setCurrent,
      data,
    ),
  downloadCvtPdf: (data: { id: string; financingProgramId: EFinancingProgram }) =>
    makeDownloadWithObject(DOWNLOAD_CVT_PDF, allApi.contract.downloadCvtPDF(data.id, data.financingProgramId), data),

  setRefinancing: (data: Refinancing) =>
    makeSave<Refinancing, CreditApplication>(
      SET_REFINANCING,
      allApi.refinancing.update,
      creditSlice.actions.setCurrent,
      data,
    ),

  setApplicantCustomerReference: (data: SetCustomerReferenceDto) =>
    makeSave<ExistingCustomerReference, CreditApplication>(
      SET_EXISTING_APPLICANT_CUSTOMER_REFERENCE,
      allApi.customerReference.setApplicantCustomerReference,
      creditSlice.actions.setCurrent,
      data,
    ),

  setCoapplicantCustomerReference: (data: SetCustomerReferenceDto) =>
    makeSave<ExistingCustomerReference, CreditApplication>(
      SET_EXISTING_COAPPLICANT_CUSTOMER_REFERENCE,
      allApi.customerReference.setCoapplicantCustomerReference,
      creditSlice.actions.setCurrent,
      data,
    ),

  findCustomers: (data: SearchCustomerDto) =>
    makeSave<SearchCustomerDto, LoanCustomer[]>(
      SET_EXISTING_COAPPLICANT_CUSTOMER_REFERENCE,
      allApi.loanCustomer.findCustomers,
      null,
      data,
    ),

  updateProofOfRelease: (data: ProofsOfRelease) =>
    makeSave<ProofsOfRelease, CreditApplication>(
      SET_PROOF_OF_RELEASE,
      allApi.credit.updateProofOfRelease,
      creditSlice.actions.setCurrent,
      data,
    ),

  createLoan: (data: { id: string; financingProgramId: EFinancingProgram }) =>
    makeSave<CreditApplication>(
      CREATE_LOAN,
      allApi.loan.create(data.financingProgramId, data.id),
      creditSlice.actions.setCurrent,
      data,
    ),

  setPaymentAccount: (data: EditBankAccountDto) =>
    makeSave<EditBankAccountDto, CreditApplication>(
      SET_PAYMENT_ACCOUNT,
      allApi.credit.setPaymentAccount,
      creditSlice.actions.setCurrent,
      data,
    ),

  sendFundDepositConfirmation: (data: { creditApplicationId: string; financingProgramId: EFinancingProgram }) =>
    makeSave<{ creditApplicationId: string; financingProgramId: EFinancingProgram }>(
      SEND_FUND_DEPOSIT_CONFIRMATION,
      allApi.credit.sendFundDepositConfirmation,
      null,
      data,
    ),

  refinanceCreditApplication: (data: RefinanceCreditApplicationDto) =>
    makeSave<CreditApplication>(REFINANCE_CREDIT_APPLICATION, allApi.credit.refinanceCreditApplication, null, data),
  copyCreditApplicationAsDraft: (data: CopyCreditApplicationAsDraftDto) =>
    makeSave<CreditApplication>(
      COPY_CREDIT_APPLICATION_AS_DRAFT,
      allApi.credit.copyCreditApplicationAsDraft,
      null,
      data,
    ),
}

export default creditEffects
